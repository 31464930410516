<template>
  <div class="food-list">
    <el-container>
      <el-main>
        <slideshow :slideshowList="slideshowList"></slideshow>
        <img class="debris" src="../../../static/scenery/debris.png" />
        <div class="content" style="width: 65%; margin: 0 auto">
          <div class="bg-image1"></div>
          <div class="bg-image2"></div>
          <div class="bg-image3"></div>
          <div class="bg-image4"></div>
          <div class="bg-image5"></div>
          <div class="content-title">
            <img
              class="home-icon"
              src="../../../static/scenery/home.png"
              alt=""
              style="width: 20; height: 20"
            />
            <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
            >
            <span style="margin-left:6px;" @click="Jump('/essential')">条子泥攻略</span>
            >
            <span style="margin-left: 6px; color: #509ab1">行程推荐</span>
          </div>
          <p class="image">行程推荐</p>
              <div
                class="scenic-list"
              >
                <div class="scenic" v-for="(item,index) in areaList" :key="index">
                  <!-- <div class="scenic-left" :style="{width: '310px',background:'url(' + item.img + ')',height: '310px'}"> -->
                  <div class="scenic-left">
                    <img :src="item.picUrl" alt="" />
                  </div>
                  <div class="scenic-right">
                    <p class="scenic-title">{{item.scenicName}}</p>
                    <p class="scenic-tel">
                      <img src="../../../static/tzngl/dianhua.png" alt="" />
                      <span>电话：{{item.telphone}}</span>
                    </p>
                    <p class="scenic-adress">
                      <img src="../../../static/tzngl/mp-location.png" alt="" />
                      <span>地址：{{item.scenicAddress}}</span>
                    </p>
                    <p class="scenic-text">
                      {{item.scenicContent}}
                    </p>
                  </div>
                </div>
                <!-- <el-pagination
                  background
                  prev-text="上一页"
                  next-text="下一页"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[5,10,15,20]"
                  layout="total,prev, pager, next"
                  :total="total"
                >
                </el-pagination> -->
              </div>
              <div class="article">
                <h1 class="title">{{ detailObj.articleTitle }}</h1>
                <p class="date">{{ detailObj.articleTime }}</p>
                <div class="text" v-html="decodeHTML(detailObj.articleContent)"> </div>
                <div class="change">
                  <span @click="goup">上一篇</span>
                  <span>
                    <el-dropdown @command="handleCommand" placement="top">
                      <span class="el-dropdown-link">
                        跳转文章
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in article" :command="item.articleTitle" :key="item.id">{{item.articleTitle}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </span>
                  <span @click="godown">下一篇</span>
                </div>
              </div>
        </div>
        <!-- <div class="bg-image1"></div>
        <div class="bg-image2"></div>
        <div class="bg-image3"></div>
        <div class="bg-image4"></div>
        <div class="bg-image5"></div> -->
        <leftFloat :floatList="floatList" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { slideshow } from '@/api/test/test'
import * as api from '@/api/test/test'
import {
  tzn_tplbAPI,
  // tzn_tpxqAPI,
  // tzn_jmspAPI,
  tzn_article,
  tzn_detail,
  tzn_Surrounding
} from '@/api/test/scenery'
export default {
  name: 'Travel',
  components: {},
  data() {
    return {
      slideshowList: [],
      activeName: '日出',
      imageTitleList: [
      {id:1,catalogueName:'日出'},
      {id:2,catalogueName:'观鸟'},
      {id:3,catalogueName:'周边景区推荐'},
      ],
      floatList: {
                list: [
                { name: "基本信息", url: "/essential" },
                { name: "舌尖美味", url: "/foodList" },
                { name: "酒店住宿", url: "/scenicSpot" },
                { name: "景区互动", url: "/interaction" },
                { name: "行程推荐", url: "flush" },
                ],
                title: "条子泥攻略",
            },
      videoList: [],
      // 分页
      currentPage: 1,
      list: [],
      id: 0,
      detailId: null,
      size:{
        page:0,
        size:5
      },
      areaList:[],
      total:null,
      detailObj: {},
      article:``,
      articleIndex:0
    }
  },
  created() {},
  mounted() {
    //头部轮播图
    slideshow().then(res => {
      this.slideshowList = res
    })
    this.tzn_article()
    api.travelRecommend({page: 0,size: 30}).then(({code,data}) => {
      if(code == 0){
        this.article = data
      }
    })
  },
  methods: {
    handleCommand(command) {
      let data  = this.article.filter(item => item.articleTitle == command)
      // this.$message('click on item ' + data);
      this.detailObj = data[0]
      this.articleIndex = this.article.findIndex( val => val === data[0]);
      },
    //分页
    handleSizeChange(val) {
    this.size.size=val
    this.tzn_Surrounding()
    },
    handleCurrentChange(val) {
    this.size.page=val
    this.tzn_Surrounding()
    },
    //teb类型切换
    handleClick(tab) {
      if (tab.name == '日出') {
        this.id = 0
        this.tzn_article()
        setTimeout(()=>{this.tzn_detail()},100)
      } else if (tab.name == '观鸟') {
        this.id = 1
        this.tzn_article()
        setTimeout(()=>{this.tzn_detail()},100)
      }
      else {
        this.tzn_Surrounding(this.size)
      }
    },
    //左边列表跳换
    handleJump(url) {
      this.$router.push(url)
    },
    //数据格式化
    decodeHTML(str) {
      var s = ''
      if (!str || str.length == 0) return ''
      s = str.replace(/&amp;/g, '&')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      s = s.replace(/&#39;/g, "'")
      s = s.replace(/&quot;/g, '"')
      // s = s.replace(/img/g, "img style='width:100%;height:auto'")
      return s
    },
    // 获取文章列表id
    async tzn_article() {
      const { data } = await tzn_article({ type: this.id })
      this.list = data
      this.detailId = this.list[0].id
      this.tzn_detail()
    },
    // 获取文章详情
    async tzn_detail() {
      const res = await tzn_detail(this.detailId)
      this.detailObj = res
      this.detailObj.articleContent = this.decodeHTML(
        this.detailObj.articleContent
      )
    },
    goup(){
      if(this.articleIndex - 1 >= 0){
        this.articleIndex = this.articleIndex - 1
      }
      this.detailObj = this.article[this.articleIndex]
    },
    godown(){
      if(this.articleIndex + 1 < this.article.length){
        this.articleIndex = this.articleIndex + 1
      }
      this.detailObj = this.article[this.articleIndex]
    }
    
  },
}
</script>

<style scoped>
.food-list {
  background: url('../../../static/scenery/backgd.png');
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
/deep/ .el-carousel__indicators {
  bottom: 5%;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.content {
  position: relative;
}
.bg-image1 {
  width: 390px;
  height: 292px;
  background-image: url('../../../static/scenery/dfbh.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -32%;
  top: 5%;
}
.bg-image2 {
  width: 428px;
  height: 353px;
  background-image: url('../../../static/scenery/hly.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  top: 36%;
}
.bg-image3 {
  width: 428px;
  height: 353px;
  background-image: url('../../../static/scenery/hlz.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  top: 30%;
}
.bg-image4 {
  width: 428px;
  height: 353px;
  background-image: url('../../../static/scenery/dfbh.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  bottom: 26%;
}
.bg-image5 {
  width: 428px;
  height: 353px;
  background-image: url('../../../static/scenery/hly.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  bottom: 12%;
}
.content-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 92px;
  background-image: url('../../../static/scenery/title.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
  letter-spacing: 5px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.content-title>span:hover{
  cursor: pointer;
  color: #509AB1;
}
.image {
  width: 450px;
  height: 250px;
  background-image: url('../../../static/scenery/img-box.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
/deep/ .el-tabs__active-bar{
    display: none;
}
/deep/ .el-tabs__nav-wrap::after{
    display: none;
}
/deep/ .el-tabs__nav.is-stretch{
    min-width: inherit;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10%;
    margin-bottom: 52px;
}
.scenic {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 49px;
}
.scenic-left {
  width: 410px;
  /* height: 449px; */
  /* display: flex; */
  position: relative;
  background-image: url('../../../static/tzngl/scenic.png');
  background-repeat: no-repeat;
  background-size: 100% 99%;
}
.scenic-left > img {
  width: 100%;
  height: 100%;
}
.scenic-right {
  width: 750px;
  background-image: url('../../../static/tzngl/right-border.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 52px 58px 61px 55px;
  color: #509ab1;
  font-weight: bold;
  /* margin-bottom: 18px; */
}
.scenic-title {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 27px;
}
.scenic-tel,
.scenic-adress {
  font-size: 16px;
  line-height: 27px;
}
.scenic-text {
  margin-top: 25px;
  font-size: 16px;
  line-height: 26px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}
.article {
  min-height: 100%;
  background-image: url('../../../static/tzngl/article-border.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  padding: 135px 145px 104px;
}
.article > img {
  width: 100%;
  height: 100%;
}
.title {
  font-size: 30px;
  color: #454545;
  line-height: 72px;
}
.date {
  font-size: 14px;
  color: #454545;
  line-height: 48px;
}
.text {
  text-align: left;
  font-size: 18px;
  color: #6d6d6d;
  line-height: 48px;
  text-indent: 2rem;
}
.change {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 146px 23px 0;
}
.change > span {
  width: 151px;
  height: 45px;
  font-size: 16px;
  color: #ffffff;
  line-height: 43px;
  background-image: url('../../../static/tzngl/change-border.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin: 18px 0 51px;
}
/deep/ .el-pagination button,
.el-pagination span {
  font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #509ab1;
}
/deep/ .el-pagination button:hover {
  color: #509ab1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #509ab1;
}

</style>
